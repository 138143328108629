import ApiService from "@/core/services/api.service";
import querystring from 'querystring';
import moment from "moment";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";

export const EXPORT_URL = 'api/samples/list/export'

// getter types
export const ERROR = 'SAMPLE/ERROR';
export const ITEMS = 'SAMPLE/ITEMS';
export const ITEM = 'SAMPLE/ITEM';
export const LOADING = 'SAMPLE/LOADING';
export const SAMPLE_ITEMS = 'SAMPLE/SAMPLE_ITEMS';
export const SAMPLES = 'SAMPLE/SAMPLES';
export const PAGE_NUMBER = 'SAMPLE/PAGE_NUMBER';
export const PER_PAGE = 'SAMPLE/PER_PAGE';
export const FILTER = 'SAMPLE/FILTER';
export const FILTER_TASK = 'SAMPLE/FILTER_TASK';

// mutation types
export const DELETE_ITEM = "SAMPLE/M/LOG_OUT";
export const SET_ITEM = "SAMPLE/M/SET_ITEM"; // setUser
export const SET_ERROR = "SAMPLE/M/SET_ERROR";
export const SET_LOADING = "SAMPLE/M/SET_LOADING";
export const SET_ITEMS = "SAMPLE/M/SET_ITEMS";
export const SET_PAGE_NUMBER = "SAMPLE/M/SET_PAGE_NUMBER";
export const SET_PER_PAGE = "SAMPLE/M/SET_PER_PAGE";
export const SET_FILTER = "SAMPLE/M/SET_FILTER";
export const SET_FILTER_TASK = "SAMPLE/M/SET_FILTER_TASK";
export const SET_ITEMS_NULL = "SAMPLE/M/SET_ITEMS_NULL";

// action types
export const STORE = "SAMPLE/A/STORE";
export const LIST = "SAMPLE/A/LIST";
export const GET = "SAMPLE/A/GET";
export const DELETE = "SAMPLE/A/DELETE";
export const UPDATE = "SAMPLE/A/UPDATE";
export const RESET_FILTERS = "SAMPLE/A/RESET_FILTERS";
export const HANDLE_INFINITE_SCROLL = `SAMPLE/HANDLE/INFINITE_SCROLL`;
export const RESET_ITEMS = `SAMPLE/HANDLE/RESET_ITEMS`;

export const BUNDLE = "SAMPLE/BUNDLE";

export const EXPORT = `EXPORT`;

const state = {
  error: null,
  items: {},
  item: {},
  loading: false,
  pageNumber: 1,
  perPage: 25,
  filter: null,
  filter_task: null,
};

const getters = {
  [ITEM]: state => {
    return state.item;
  },
  [ITEMS]: state => {
    return state.items;
  },
  [PAGE_NUMBER]: state => {
    return state.pageNumber;
  },
  [PER_PAGE]: state => {
    return state.perPage;
  },
  [LOADING]: (state) => {
    return state.loading;
  },
  [ERROR]: state => {
    return state.perPage;
  },
  [FILTER]: state => {
    return state.filter;
  },
  [FILTER_TASK]: state => {
    return state.filter_task;
  },
  [SAMPLE_ITEMS]: state => {
    let items = [];
    for (let key in state.items.data) {
      let currentData = state.items.data[key];
      items.push({
        'id': currentData.id,
        'sample_type_id': currentData.sample_type_id,
        'image': currentData.image,
        'product_name': currentData.name,
        'order_date': moment(currentData.order_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
        'updated_at': moment(currentData.updated_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
        'completed_at': currentData.completed_at ? moment(currentData.completed_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '-',
        'collection_number': currentData.collection_number,
        'model_number': currentData.model_number,
        'customer_name': currentData.company.name,
        'deadline': currentData.deadline != null ? moment(currentData.deadline, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '-',
        'model': currentData.model_name || '-',
        'model_description': currentData.product_model ? currentData.product_model.translations[0].name : '-',
        'amount': +currentData.sample_type_id === 1 ? currentData.amount + ' / ' + currentData.delivered_amount : '-',
        'copied_count': currentData.copied_count,
        'is_completed': currentData.is_completed,
        'status': currentData.sample_status.name, // TODO: need to change after translation added
        'status_id': currentData.sample_status.id,
      });
    }
    return items;
  },
  [SAMPLES]: state => {
    return state.items.data;
  }
};

const mutations = {
  [SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [SET_ERROR](state, payload) {
    state.error = payload;
  },
  [SET_ITEMS](state, payload) {
    state.items = payload;
  },
  [SET_PAGE_NUMBER](state, payload) {
    state.pageNumber = payload;
  },
  [SET_PER_PAGE](state, payload) {
    state.perPage = payload;
  },
  [SET_FILTER](state, payload) {
    state.filter = payload;
  },
  [SET_FILTER_TASK](state, payload) {
    state.filter_task = payload;
  },
  [SET_ITEMS_NULL](state) {
    state.items = {};
    state.loading = false;
    state.pageNumber = 1;
    state.perPage = 25;
  }
};

const actions = {
  [HANDLE_INFINITE_SCROLL]: (context, payload) => {
    if (context.state.loading) {
      return new Promise(function (resolve) {
        resolve({
          status: true,
          data: payload
        });
      });
    }
    context.commit(SET_LOADING, true);
    if (payload.$state == null) {
      context.commit(SET_ITEMS, {});
      context.state.pageNumber = 1;
    }
    if (document.activeElement !== document.body) document.activeElement.blur();

    let items = { ...context.state.items };

    if (Number(items.last_page ? items.last_page : Infinity) < Number(context.state.pageNumber)) {
      context.commit(SET_LOADING, false);
      return new Promise(function (resolve) {
        resolve({
          status: false,
          end: true,
          error: "End of the scroll"
        });
      });
    }
    let filters = payload.filters;
    filters.page_number = context.state.pageNumber;
    filters.per_page = context.state.perPage;

    return ApiService.get('api/samples?' + querystring.stringify(filters)).then(response => {
      if (!Object.keys(items).length) {
        items = { ...response.data };
      } else {
        items.data = [...items.data, ...response.data.data];
      }
      delete response.data.data;
      items = { ...items, ...response.data };
      if (Number(items.last_page) >= context.state.pageNumber) {
        context.state.pageNumber += 1;
      }
      context.commit(SET_ITEMS, items);
      context.commit(SET_LOADING, false);
      return { status: true, data: payload };
    }).catch(error => {
      context.commit(SET_LOADING, false);
      return { status: false, error };
    });
  },

  [EXPORT]: (context, payload) => {
    let url = EXPORT_URL;
    let filters = payload.filters || null;
    let serviceUrl = url;
    if (filters) {
      serviceUrl = `${url}?` + querystring.stringify(filters);
    }
    context.commit(SET_LOADING, true);
    let config = {
      responseType: 'blob',
    }
    return ApiService.get(serviceUrl, "", config).then((response) => {
      context.commit(SET_LOADING, false);
      return { status: true, data: response.data };
    }).catch((error) => {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, error);
      return { status: false, data: error };
    });
  },
  [RESET_ITEMS]:(context) => {
    context.commit(SET_ITEMS_NULL);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

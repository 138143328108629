// GETTERS
import ApiService from "@/core/services/api.service";

export const LANGUAGE = 'SYSTEM/LANGUAGE';
export const LANGUAGES = 'SYSTEM/LANGUAGES';
export const GENERAL_SETTING = 'SYSTEM/G/GENERAL_SETTING';
export const MENUS = 'SYSTEM/G/MENUS';
export const SUPPLIER_COMPANIES = 'SYSTEM/G/SUPPLIER_COMPANIES';
// MUTATIONS
export const SET_LANGUAGE = 'SYSTEM/LANGUAGE';
export const SET_GENERAL_SETTING = 'SYSTEM/M/GENERAL_SETTING';
export const SET_SETTINGS = "setSettings";

// ACTIONS
export const GET_PERSONALIZE_SETTINGS = "getPersonalizeSettings";


const state = {
    language: localStorage.getItem('language') || 'tr',
    generalSettings: [{}],
    languages: [],
    menu: {},
    supplierCompanies: [],
};

const getters = {
    [LANGUAGE]: state => {
        for (let i = 0; i < state.languages.length; i++) {
            if (state.language === state.languages[i].code) {
                return state.languages[i];
            }
        }
    },
    [LANGUAGES]: state => {
        return state.languages;
    },
    [GENERAL_SETTING]: state => {
        return state.generalSettings;
    },
    [MENUS]: state => {
        return state.menu;
    },
    [SUPPLIER_COMPANIES]: state => {
        if (state.supplierCompanies === null || state.supplierCompanies === undefined) return [];
        return state.supplierCompanies;
    }
};

const mutations = {
    [SET_LANGUAGE](state, payload) {
        let isValid = false;
        for (let i = 0; i < state.languages.length; i++) {
            if (payload === state.languages[i].code) {
                state.language = payload;
                localStorage.setItem('language', payload);
                isValid = true;
                break;
            }
        }
        if (!isValid) console.error(`Invalid language code was passed: ${payload}`)
    },
    [SET_GENERAL_SETTING](state, payload) {
        state.generalSettings = payload;
    },
    [SET_SETTINGS](state, payload) {
        state.menu = payload.menu;
        state.generalSettings = payload.settings;
        state.languages = payload.languages;
        state.supplierCompanies = payload.supplier_companies;
        if (!localStorage.getItem('language')) {
            localStorage.setItem('language', 'tr');
        }
    },
};

const actions = {
    [GET_PERSONALIZE_SETTINGS](context, payload) {
        return ApiService.get("api/user/personalize-settings").then(({data}) => {
            context.commit(SET_SETTINGS, data);
            return true;
        }).catch(() => {
            return false;
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

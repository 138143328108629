import ApiService from "@/core/services/api.service";
import {AUTH} from "@/core/services/store/auth.module";

import querystring from 'querystring';
import moment from "moment";
import {
    LARAVEL_DATE_TIME_FORMAT,
    LARAVEL_RAW_DATE_TIME_FORMAT,
} from "@/core/config/constant";

const BASE = 'SAMPLE/CREATE/ORIGINAL_SAMPLE';

export const LOADING = `${BASE}/LOADING`;
export const ERROR = `${BASE}/ERROR`;
export const SUCCESS = `${BASE}/SUCCESS`;
export const SAMPLE_IMAGE = `${BASE}/SAMPLE_IMAGE`;
export const COMPANIES = `${BASE}/COMPANIES`;
export const PRODUCT_TYPES = `${BASE}/PRODUCT_TYPES`;
export const SELECTED_COMPANY = `${BASE}/SELECTED_COMPANY`;
export const SELECTED_PRODUCT_TYPE = `${BASE}/SELECTED_PRODUCT_TYPE`;
export const PRODUCT_NAME = `${BASE}/PRODUCT_NAME`;
export const SAMPLE_USERS = `${BASE}/SAMPLE_USERS`;
export const SELECTED_USER = `${BASE}/SELECTED_USER`;
export const PRODUCT_CONTENTS = `${BASE}/PRODUCT_CONTENTS`;

export const SET_LOADING = `${BASE}/M/LOADING`;
export const SET_ERROR = `${BASE}/M/ERROR`;
export const SET_SUCCESS = `${BASE}/M/SUCCESS;`;
export const SET_SAMPLE_IMAGE = `${BASE}/M/SAMPLE_IMAGE`;
export const SET_COMPANIES = `${BASE}/M/COMPANIES`;
export const SET_PRODUCT_TYPES = `${BASE}/M/PRODUCT_TYPES`;
export const SET_SELECTED_COMPANY = `${BASE}/M/SELECTED_COMPANY`;
export const SET_SELECTED_PRODUCT_TYPE = `${BASE}/M/SELECTED_PRODUCT_TYPE`;
export const SET_PRODUCT_NAME = `${BASE}/M/PRODUCT_NAME`;
export const SET_SAMPLE_USERS = `${BASE}/M/SAMPLE_USERS`;
export const SET_SELECTED_USER = `${BASE}/M/SELECTED_USER`;
export const SET_PRODUCT_CONTENTS = `${BASE}/M/PRODUCT_CONTENTS`;
export const APPEND_PRODUCT_CONTENTS = `${BASE}/M/APPEND_PRODUCT_CONTENTS`;
export const DELETE_PRODUCT_CONTENT_BY_ID = `${BASE}/M/DELETE_PRODUCT_CONTENT_BY_ID`;
export const RESET_FORM = `${BASE}/RESET_FORM`;

export const GET_COMPANIES = `${BASE}/A/GET_COMPANIES`;
export const GET_REQUIRED_DATA_FOR_ORIGINAL_SAMPLE = `${BASE}/A/GET_REQUIRED_DATA_FOR_ORIGINAL_SAMPLE`;
export const CREATE_ORIGINAL_SAMPLE = `${BASE}/A/CREATE_ORIGINAL_SAMPLE`;


const state = {
    loading: false,
    error: null,
    success: null,
    sampleImage: null,
    companies: [],
    productTypes: [],
    selectedCompany: null,
    selectedProductType: null,
    productName: null,
    sampleUsers: [],
    selectedUser: null,
    productContents: [],

};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [SAMPLE_IMAGE]: (state) => {
        return state.sampleImage;
    },
    [COMPANIES]: (state) => {
        return state.companies;
    },
    [PRODUCT_TYPES]: (state) => {
        return state.productTypes;
    },
    [SELECTED_COMPANY]: (state) => {
        return state.selectedCompany;
    },
    [SELECTED_PRODUCT_TYPE]: (state) => {
        return state.selectedProductType;
    },
    [PRODUCT_NAME]: (state) => {
        return state.productName;
    },
    [SAMPLE_USERS]: (state) => {
        return state.sampleUsers;
    },
    [SELECTED_USER]: (state) => {
        return state.selectedUser;
    },
    [PRODUCT_CONTENTS]: (state) => {
        return state.productContents;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [SET_SAMPLE_IMAGE]: (state, payload) => {
        state.sampleImage = payload;
    },
    [SET_SELECTED_PRODUCT_TYPE]: (state, payload) => {
        state.selectedProductType = payload;
    },
    [SET_SELECTED_COMPANY]: (state, payload) => {
        state.selectedCompany = payload;
    },
    [SET_PRODUCT_NAME]: (state, payload) => {
        state.productName = payload;
    },
    [SET_SAMPLE_USERS]: (state, payload) => {
        state.sampleUsers = payload;
    },
    [SET_SELECTED_USER]: (state, payload) => {
        state.selectedUser = payload;
    },
    [RESET_FORM]: (state) => {
        state.selectedCompany = null;
        state.selectedProductType = null;
        state.productName = null;
        state.selectedUser = null;
        state.productContents = [];
    },

    [APPEND_PRODUCT_CONTENTS]: (state, payload) => {
        if(Array.isArray(state.productContents))
        {
            state.productContents.push(payload);
        }else{
            state.productContents = [
                payload
            ];
        }
    },
    [DELETE_PRODUCT_CONTENT_BY_ID]: (state, payload) => {
        if(Array.isArray(state.productContents)){
            for(let i = 0; i < state.productContents.length; i++){
                if(state.productContents[i].id.toString() === payload.toString()){
                    state.productContents.splice(i, 1);
                    break;
                }
            }
        }
    },
    [SET_PRODUCT_CONTENTS]: (state, payload) => {
        state.productContents = payload;
    },
    [SET_COMPANIES]: (state, payload) => {
        let companies = [];
        for(let i = 0; i < payload.length; i++){
            companies.push({
                id: payload[i].id,
                name: payload[i].name,
                avatar: payload[i].avatar_file,
            });
        }
        state.companies = companies;
    },
    [SET_PRODUCT_TYPES]: (state, payload) => {
        let productTypes = [];
        for(let i = 0; i < payload.data.length; i++){
            productTypes.push({
                id: payload.data[i].id,
                name: payload.data[i].translations[0].name,
            });
        }
        state.productTypes = productTypes;
    }

};

const actions = {
    [GET_COMPANIES]: (context, payload) => {
        context.commit(SET_LOADING, true);
        ApiService.get(`api/companies`).then((result)=>{
            context.commit(SET_COMPANIES, result.data);
            context.commit(SET_LOADING, false);
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [GET_REQUIRED_DATA_FOR_ORIGINAL_SAMPLE]: (context, payload) => {
        context.commit(RESET_FORM);
        context.commit(SET_LOADING, true);
        let promises = [];
        promises.push(ApiService.get(`api/companies`));
        promises.push(ApiService.get(`api/sample-types`));
        promises.push(ApiService.get(`api/sample-users`));

        Promise.all(promises).then((results) => {
            context.commit(SET_COMPANIES, results[0].data);
            context.commit(SET_PRODUCT_TYPES, results[1].data);
            context.commit(SET_SAMPLE_USERS, results[2].data);

            context.commit(SET_LOADING, false);
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [CREATE_ORIGINAL_SAMPLE]: (context, payload) => {
        let form = new FormData();
        context.commit(SET_LOADING, true);
        let USER =  payload.user;
        let USER_ID = '';
        if(context.state.selectedUser === null){
            USER_ID = USER.user_id;
        }
        else {
            USER_ID = context.state.selectedUser;
        }
        form.append('user_id', USER_ID);
        form.append('company_id', context.state.selectedCompany.id);
        form.append('sample_type_id', context.state.selectedProductType.id);
        form.append('name', context.state.productName);
        form.append('sample_status_id', '1');

        return ApiService.post(`api/samples`, form).then((response) => {
            let sample = response.data;
            let contentPromises = [];
            let selectedItemIndex = null;
            for(let i = 0; i < context.state.productContents.length; i++){
                let contentForm = new FormData();
                contentForm.append(`content_type_id`, context.state.productContents[i].content_type_id);
                contentForm.append(`content`, context.state.productContents[i].data);
                if(Number(context.state.productContents[i].is_selected) === 1){
                    selectedItemIndex = i;
                }
                contentPromises.push(ApiService.post(`api/samples/${sample.id}/contents`, contentForm));
            }

           let USER = payload.user;
            let USER_ID = '';
            if(context.state.selectedUser === null){
               USER_ID = USER.user_id;
            }
            else {
                USER_ID = context.state.selectedUser;
            }
            // ApiService.post(`api/samples/${sample.id}/users`, {user_id: USER_ID});
            return Promise.all(contentPromises).then((responses) => {
                let form = { default_content_id:  responses[selectedItemIndex].data.id};
                return ApiService.put(`api/samples/${sample.id}`, form).then(() => {
                    context.commit(SET_LOADING, false);
                    return {status: true, data: sample};
                });
            });
        }).catch((error) => {
            return {status: false, error: error};
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
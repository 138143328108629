import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {Service} from 'axios-middleware';
import router from "@/router";

import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: 'aes', isCompression: true });
let VUE_APP_BACKEND_APP_URL = localStorage.getItem('VUE_APP_BACKEND_APP_URL');
const baseURL = VUE_APP_BACKEND_APP_URL;
const service = new Service(axios);

const CONFIG = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Content-Type': 'application/json'
  }
};

service.register({
  onRequest(config) {
    if (config.url == baseURL + '/api/token') {
      delete config.headers.Authorization;
    }
    return config;
  },
});


let isRefreshing = false;
let failedQueue = [];

// Add a request interceptor
axios.interceptors.request.use(
    config => {
      const token = ls.get('token');
      const language = ls.get('language');
      if (token && config.url != 'api/token') {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      config.headers['X-Content-Language'] = language;
      config.headers['Content-Type'] = 'application/json';
      return config;
    }, error => {
      Promise.reject(error)
    });

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      const originalRequest = err.config;

      if (err.response.status === 403) {
        router.push({'name': '403'});
        return Promise.reject(err);
      }

      if (err.response.status === 404) {
        router.push({'name': '404'});
        return Promise.reject(err);
      }

      if (err.response.status === 401 && (originalRequest.url === 'api/token' || originalRequest.url === 'api/logout')) {
        return Promise.reject(err);
      }

      if (err.response.status === 401 &&  (originalRequest.url ===  baseURL + '/api/token/refresh' || originalRequest.url === '/api/token/refresh')) {
        window.localStorage.clear();
        ls.removeAll();
        router.push({name: 'login'});
        setTimeout(() => {
          ls.removeAll();
          location.reload();
        }, 500);
      }

      if (err.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({resolve, reject});
          })
              .then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
              })
              .catch(err => {
                return Promise.reject(err);
              });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        let refreshToken = ls.get('refresh_token');

        return new Promise(function (resolve, reject) {
          axios
              .post(baseURL + '/api/token/refresh', {
                refresh_token: refreshToken
              })
              .then(({data}) => {
                ls.set('token', data.access_token);
                ls.set('refresh_token', data.refresh_token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                processQueue(null, data.access_token);
                resolve(axios(originalRequest));
              })
              .catch(err => {
                if (err.response.status === 401 || err.response.status === 429 || err.response.status === 422 || err.response.status === 500) {
                  window.localStorage.clear();
                  ls.removeAll();
                  router.push({name: 'login'});
                  setTimeout(() => {
                    ls.removeAll();
                    location.reload();
                  }, 500);
                }
                processQueue(err, null);
                reject(err);
              })
              .then(() => {
                isRefreshing = false;
              });
        });
      }

      return Promise.reject(err);
    }
);

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = baseURL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';
    Vue.axios.defaults.headers.common["Access-Control-Allow-Methods"] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
    Vue.axios.defaults.headers.common["Access-Control-Allow-Headers"] = 'Origin, Content-Type, X-Auth-Token, X-Content-Language';
    Vue.axios.defaults.headers.common["Content-Type"] = 'application/json';
    Vue.axios.defaults.headers.common["X-Content-Language"] = ls.get('language');
    Vue.axios.defaults.headers.common["Authorization"] = 'Bearer ' + ls.get('token');
  },

  query(resource, params, headers={}, config=CONFIG) {
    return Vue.axios.get(resource, params, config);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param config
   * @returns {*}
   */
  get(resource, slug = "", config = CONFIG) {
    return Vue.axios.get(`${resource}${slug ? '/' : ''}${slug}`, config);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param headers
   * @param config
   * @returns {*}
   */
  post(resource, params, headers={}, config=CONFIG) {
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Set the PATCH HTTP request
   * @param resource
   * @param params
   * @param headers
   * @param config
   * @returns {*}
   */
  patch(resource, params, headers={}, config=CONFIG) {
    headers['Content-Type'] = 'application/json';
    return Vue.axios.patch(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param config
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, config = CONFIG) {
    return Vue.axios.put(`${resource}${slug ? '/' : ''}${slug}`, params, config);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, config = CONFIG) {
    return Vue.axios.put(`${resource}`, params, config);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param config
   * @returns {*}
   */
  delete(resource, config = CONFIG) {
    return Vue.axios.delete(resource, config);
  }
};

export default ApiService;

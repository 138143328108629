import {mapGetters} from "vuex";
import moduleUser, {MODULE_NAME as MODULE_USER, USER} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import jsPDF from "jspdf";
import domtoimage from 'dom-to-image';
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: 'aes', isCompression: true });

const _MODULE_USER = MODULE_USER;

export default {
    beforeCreate() {
        function registerStoreModule(moduleName, storeModule) {
            if (!(store && store.state && store.state[moduleName])) {
                store.registerModule(moduleName, storeModule)
            }
        }

        registerStoreModule(_MODULE_USER, moduleUser);
    },
    data()  {
        return {
            OrderCreateScope: 'Order:create',
            OrderUpdateScope: 'Order:update',
        }
    },
    computed: {
        ...mapGetters({
            layoutConfig: 'layoutConfig',
        }),
        isUserGranted() {
            let self = this;
            try {
                let resultStatus = false;
                let authUserLocal = ls.get('user') ? JSON.parse(ls.get('user')) : null;
                let authUserState = self.authUser;
                let authUser = authUserState || authUserLocal;
                return function (module, actions, _showModal = true, strict = false) {
                    if (!authUser) {
                        return false;
                    }

                    if (!strict) {
                        // checking user is super admin
                        let isSuperAdmin = authUser.scopes.find(subItem => subItem.model === 'Staff' && subItem.action === 'superAdmin');

                        if (isSuperAdmin) {
                            resultStatus = true;
                            return true;
                        }
                    }

                    actions = actions.map(action => _.lowerCase(action));
                    let scopes = _.map(authUser.scopes.filter(subItem => _.lowerCase(subItem.model) === _.lowerCase(module)), 'action');

                    if (scopes.includes('all')) {
                        resultStatus = true;
                        return true;
                    } else {
                        let item =  scopes.find(item => actions.includes(_.lowerCase(item)));
                        if (item) {
                            resultStatus = true;
                         } else {
                            resultStatus = false;
                        }
                    }

                    if (!resultStatus && _showModal) {
                        setTimeout(() => {
                            self.sweetAlertError(this.$t('general.you_have_no_authorized_to_do_this_action'));
                        }, 500);
                    }

                    return resultStatus;
                }
            } catch (e) {
                return false;
            }
        },

        OrderModuleName() {
            return 'order';
        },
        authUser() {
            return this.$store.getters[_MODULE_USER + '/' + USER]
        },
        generalSetting () {
            let item =  this.$store.getters[_MODULE_USER + '/' + USER]
            if (item) {
                return item.settings;
            }
            return [];
        },
        userSuppliersCompanies () {
            let item =  this.$store.getters[_MODULE_USER + '/' + USER]
            if (item) {
                return item.supplier_companies;
            }
            return [];
        },
        systemLanguages () {
            let item =  ls.get('languages')
            if (item) {
                return JSON.parse(item);
            }
            return [];
        },
        activeLanguageId () {
            let languageCode =  ls.get('language');
            let languages = this.systemLanguages;
            let language = languages.find(item => item.code === languageCode)
            if (language) {
                return language.id;
            }
            return 1;
        },
        language () {
            let item =  this.$store.getters[_MODULE_USER + '/' + USER]
            if (item) {
                for(let i = 0; i < item.languages.length; i++){
                    if(state.language === item.languages[i].code){
                        return item.languages[i];
                    }
                }
            }
            return [];
        },
    },
    methods: {
        formatNumber(number) {
            return new Intl.NumberFormat().format(number);
        },
        turkishToUpper(string){
            var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
            string = string.replace(/(([iışğüçö]))/g, function(letter){ return letters[letter]; })
            return string.toUpperCase();
        },
        turkishToLower(string){
            var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
            string = string.replace(/(([İIŞĞÜÇÖ]))/g, function(letter){ return letters[letter]; })
            return string.toLowerCase();
        },
        cloneForce(object) {
            return JSON.parse(JSON.stringify(object));
        },
        getIconByKey(key, configs = null) {
            if (configs) {
                return `<img 
                            src="${process.env.BASE_URL + this.layoutConfig(key)}" 
                            class="${configs.class || 'w-25px h-25px object-cover mr-2'}"
                            style="${configs.style || ''}"
                        >`;
            }
            return process.env.BASE_URL + this.layoutConfig(key);
        },
        downloadImage(elementId = 'image-holder', filename = 'download') {
            domtoimage.toBlob(document.getElementById(elementId), {})
                .then(function (blob) {
                    let saveBlob = (function () {
                        let a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        return function (blob, fileName) {
                            let url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = fileName;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        };
                    }());
                    saveBlob(blob, filename + '.jpg');
                });
        },

        async downloadPdf(elementId = 'pdf-holder', filename = 'download', closeAfterDownload = true) {
            // JS way
            /*
            let printContents = document.getElementById('pdf-holder').innerHTML;
            let originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            return;
            */
            const el = document.getElementById(elementId);
            const options = {
                type: 'dataURL',
                scale: 2,
                useCORS: true,
                width: el.getBoundingClientRect().width,
                height: el.getBoundingClientRect().height,
            };
            let result = await this.$html2canvas(el, options);
            const pdf = new jsPDF({
                orientation: 'portrait',
            });
            const imgProps = pdf.getImageProperties(result);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(result, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(filename + '.pdf');
            if (closeAfterDownload) {
                setTimeout(function () {
                    window.close();
                }, 1000);
            }
        },
        closeModalByName(name) {
            this.$modal.hide(name);
        },
        openModalByName(name) {
            this.$modal.show(name);
        },
        showSuccessNotification() {
            let successMessage = this.$route.params.success;
            if (successMessage != null) {
                this.$notify({
                    group: 'notification',
                    class: 'bg-success',
                    title: this.$t('general.success'),
                    position: 'bottom right',
                    text: successMessage
                });
            }
        },
        showErrorNotification(error = null) {
            let errorMessage = error || this.$route.params.error;
            if (errorMessage != null) {
                this.$notify({
                    group: 'notification',
                    class: 'bg-error',
                    title: this.$t('general.error'),
                    position: 'bottom right',
                    text: this.$t(errorMessage)
                });
            }
        },
        getLanguageById(id) {
            let languages = this.systemLanguages;
            for (let i = 0; i < languages.length; i++) {
                if (Number(languages[i].id === Number(id)) || languages[i].code.toString() === id.toString()) {
                    return languages[i];
                }
            }
            return null;
        },
        getValueByDottedKey(object, key) {
            if (object === undefined || object === null || object === {}) return [];
            let temp = {...object};
            try {
                let splitKeys = key.split('.');
                for (let i = 0; i < splitKeys.length; i++) {
                    temp = temp[splitKeys[i]];
                }
            } catch (e) {
                return [];
            }
            return temp;
        },
        randomStr(length, include=true) {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            if(include){
                characters += '!@#$%^&*()_+';
            }
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        capitalize(string) {
            let pieces = string.split(' '), result = '';
            for (let i = 0; i < pieces.length; i++) {
                result += pieces[i].charAt(0).toUpperCase() + pieces[i].slice(1).toLowerCase();
                if ((pieces.length - 1) !== i) {
                    result += ' ';
                }
            }
            return result;
        },
        convertArrayToObjectByKeyForExpense(array, key, keys) {
            let result = {};
            try {
                for (let i = 0; i < array.length; i++) {
                        if(array[i]['name']){
                            result[array[i]['logicalref']] = array[i]['name'];
                        }
                        if(array[i]['description']){
                            result[array[i]['logicalref']] = array[i]['description'];
                        }
                }
                return result;
            } catch (e) {
                return {};
            }
        },
        convertArrayToObjectByKey(array, key, keys = null) {
            let result = {};
            try {
                for (let i = 0; i < array.length; i++) {
                    if (keys !== null) {
                        if (keys.length === 1) {
                            result[this.getValueByDottedKey(array[i], key)] = this.getValueByDottedKey(array[i], keys[0]);
                        } else if (!Array.isArray(keys)) {
                            result[this.getValueByDottedKey(array[i], key)] = this.getValueByDottedKey(array[i], keys);
                        } else {
                            let temp = {};
                            for (let j = 0; j < keys.length; j++) {
                                temp[keys[j]] = this.getValueByDottedKey(array[i], array[i][keys[j]]);
                            }
                            result[this.getValueByDottedKey(array[i], key)] = temp;
                        }
                    } else {
                        result[this.getValueByDottedKey(array[i], key)] = array[i];
                    }
                }
                return result;
            } catch (e) {
                return {};
            }
        },
        convertArrayToObjectByKeyList(array, key, keys = null, keyPrefix = ' / ') {
            let result = {};
            try {
              for (let i = 0; i < array.length; i++) {
                if (keys !== null) {
                  let text='';
                  for(let j = 0; j < keys.length; j++){
                      text += this.getValueByDottedKey(array[i], keys[j])+(keys.length-1!=j? keyPrefix :'');
                  }
                  result[array[i][key]] = text;
                }
              }
              return result;
            } catch (e) {
              return {};
            }
          },
          convertArrayToObjectByKeyListForFınancePayment(array, key, keys = null, keyPrefix = ' / ') {
            let result = {};
            try {
              for (let i = 0; i < array.length; i++) {
                if (keys !== null) {
                  let text='';
                  for(let j = 0; j < keys.length; j++){
                      text += this.getValueByDottedKey(array[i], keys[j])+(keys.length-1!=j? keyPrefix :'');
                  }
                  result[array[i][key]] = text;
                }
              }
              return result;
            } catch (e) {
              return {};
            }
          },
        convertObjectToArray(object, key = null) {
            let result = [];
            try {
                for (let index in object) {
                    if (key !== null) {
                        result.push({...object[index], ...{[key]: index}})
                    } else {
                        result.push({...object[index]})
                    }
                }
                return result;
            } catch (e) {
                return [];
            }
        },
        
        openInNewTab(url) {
            window.open(url, '_blank');
        },
        isUserAuthorized(scope) {
            let self = this;
            if (this.authUser) {
                return self.authUser.scopes.find(subItem => subItem.model + ':' + subItem.action === scope);
            }
            return false;
        },
        findSameRolesByIds(roles) {
            let user = this.authUser;
            let role_ids = [];
            for (let i = 0; i < user.scopes.length; i++) {
                let temp = user.scopes[i];
                if (!role_ids.includes(Number(temp.role_user_id))) {
                    role_ids.push(Number(temp.role_user_id));
                }
            }
            if (!Array.isArray(roles)) {
                roles = [roles];
            }
            let existedRoles = [];
            role_ids.every(i => {
                if (roles.includes(i)) {
                    existedRoles.push(i);
                }
            });
            return existedRoles;
        },
        createFormDataForList(formData, key, data) {
            if (data === Object(data) || Array.isArray(data)) {
                for (let i in data) {
                    if (i.toString() === 'file_names') {
                        continue;
                    }
                    if (data.file_names !== undefined) {
                        if (data.file_names.includes(i)) {
                            if(key){
                                formData.append(key + '[' + i + ']', data[i]);
                            }else{
                                formData.append(i, data[i]);
                            }
                            continue;
                        }
                    }
                    if(key){
                        this.createFormDataForList(formData, key + '[' + i + ']', data[i]);
                    }else{
                        this.createFormDataForList(formData, i, data[i]);
                    }
                }
            } else {
                if (data === null) {
                    return;
                }
                formData.append(key, data);
            }
        },
        parseGradingOrder(grading) {
            let order;
            if (grading.includes('s')) order = -1;
            else if (grading.includes('m')) order = 0;
            else if (grading.includes('l')) order = 1;
            const n = Number(grading.match(/\d+(?!X)/));
            const numXes = grading.match(/x*/)[0].length;
            const mul = n ? n : numXes + 1;
            return order * mul;
        },
        convertArrayToObject(array, key){
            const initialValue = {};
            return array.reduce((obj, item) => {
              return {
                ...obj,
                [item[key]]: item,
              };
            }, initialValue);
          },
        sortArrayOfObjects(array) {
            return array.sort((a, b) => {
                if (!isNaN(a) && !isNaN(b)) {
                    return a - b;
                }
                if (!isNaN(a) && isNaN(b)) {
                    return -1;
                }
                if (isNaN(a) && !isNaN(b)) {
                    return 1;
                }
                if (isNaN(a) && isNaN(b)) {
                    let aOrder = parseGradingOrder(a.toLowerCase());
                    let bOrder = parseGradingOrder(b.toLowerCase());
                    return aOrder - bOrder;
                }
            });
        }
    },
};

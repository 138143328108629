import Vue from 'vue';
import VueI18n from "vue-i18n";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: 'aes', isCompression: true });

Vue.use(VueI18n);

// set the current language for i18n.
let locale = ls.get('language');

if(!locale){
  ls.set('language', 'tr');
  locale = 'tr';
}

function getTranslations() {
  let VUE_APP_APP_URL=localStorage.getItem('VUE_APP_APP_URL')
  if(VUE_APP_APP_URL==null||VUE_APP_APP_URL==''){
    return ApiService.get(`https://pulpopro.com/aletex-backend/public/api/localization`);
  }else{
    return ApiService.get(`/api/localization`);
  }
}

function prepareConfig(data) {
  let messages = {};
  let languages = data.languages;
  ls.set('languages', JSON.stringify(languages));
  languages.forEach(function(currentValue) {
    Vue.set(messages, currentValue.code, data.translations[currentValue.code]);
  });

  moment.locale(locale);

  // Create VueI18n instance with options
  return new VueI18n({
    messages, // set locale messages
    locale: locale
  });
}

export { prepareConfig, getTranslations };

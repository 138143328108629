import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import sampleIndex from './sample/samples.index.module';
import meetingList from './sample/create/meeting-list.module';
import meetingDetail from './sample/create/meeting-detail.moduel';
import taskIndex from '@/core/services/store/sample/task/task-index.module';
import sampleProductEdit from '@/core/services/store/sample/edit/sample.edit.module';
import createOriginalSample from '@/core/services/store/sample/original-sample/create.original.sample.module';
import panelCRUD from "@/core/services/store/panel-managment/crud/panel.management.module";
import systemOptions from "@/core/services/store/system-options.module";
import REST from "@/core/services/store/REST.module";
import newWaybillEntry from "@/core/services/store/waybill/entry/new-waybill-entry.module";
import newWaybillExit from "@/core/services/store/waybill/exit/new-waybill-exist.module";
import menu from "@/core/services/store/menu.module";
import editOrder from "@/core/services/store/order/edit-order.module";
import order from "@/core/services/store/order/order.module";
import esc from "@/core/services/store/esc/esc.module";
import modelHouse from "@/core/services/store/modelhouse/modelhouse.module";
import waybill from "@/core/services/store/waybill/waybill.module";
import qrScanner from "@/core/services/store/qr-scanner/qr_scanner.module";
import incomingInvoice from "@/core/services/store/invoice/incoming_invoice.module";
import customIncomingInvoice from "@/core/services/store/invoice/custom_incoming_invoice.module";
import approvedInvoices from "@/core/services/store/invoice/approved_invoices.module";
import followingProduction from "@/core/services/store/following-production/following_production.module";
import outgoingInvoice from "@/core/services/store/invoice/outgoing_invoice.module";
import followingExport from "@/core/services/store/following-export/following_export.module";
import packingList from "@/core/services/store/packing-list/packing_list.module";
import packingListProduction from "@/core/services/store/packing-list/packing_list_production.module";
import timeSheet from "@/core/services/store/time-sheet/time_sheet.module";
import logoUsers from "@/core/services/store/logo-accounts/logo_accounts.module";
import snapType from "@/core/services/store/snap-type/snapType.module";
import expirationType from "@/core/services/store/expiration-type/expirationType.module";
import fabricPrintType from "@/core/services/store/fabric-print-type/fabricPrintType.module";
import customer from "@/core/services/store/customer/customer.module";
import invoiceCustomApprovalTypeUsers from "@/core/services/store/invoice-custom-approval-users/invoice_custom_approval_users.module";
import invoiceCustomApprovalStatuses from "@/core/services/store/invoice-custom-approval-statuses/invoice_custom_approval_statuses.module";
import customInvoice from "@/core/services/store/invoice/custom_invoices.module";
import packingListsStatuses from "@/core/services/store/packing-list-statuses/packing_list_statuses.module";
import financePayments from "@/core/services/store/finance-payments/finance_payments.module"
import bankInstruction from "@/core/services/store/bank-instruction/bankInstruction.module"
import invoiceLogoIndex from "@/core/services/store/invoice/invoice_logo_index.module";
import endorsement from "@/core/services/store/report/endorsementReport.module";
import landingDeadline from "@/core/services/store/report/loadingDeadlineReport.module";
import purchasingWaybillReport from "@/core/services/store/report/purchasingWaybillReport.module";
import purchasingInvoiceReport from "@/core/services/store/report/purchasingInvoiceReport.module";
import purchasingInstructionReport from "@/core/services/store/report/purchasingInstructionReport.module";
import fireReport from "@/core/services/store/report/fireReport.module";
import supplierCompanyEdit from "@/core/services/store/supplier-company-edit/supplier.company.edit.module";
import WorkmanshipReport from "@/core/services/store/report/workmanshipReport.module";
import EscReport from "@/core/services/store/report/escReport.module";
import SupplierDeadlineReport from "@/core/services/store/report/supplierDeadlineReport.module";
import SupplierPerformanceReport from "@/core/services/store/report/supplierPerformanceReport.module"; 


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    sampleIndex,
    meetingList,
    meetingDetail,
    taskIndex,
    sampleProductEdit,
    createOriginalSample,
    panelCRUD,
    systemOptions,
    REST,
    newWaybillEntry,
    newWaybillExit,
    menu,
    editOrder,
    order,
    esc,
    modelHouse,
    waybill,
    incomingInvoice,
    outgoingInvoice,
    followingExport,
    packingList,
    packingListProduction,
    timeSheet,
    followingProduction,
    approvedInvoices,
    logoUsers,
    snapType,
    expirationType,
    fabricPrintType,
    customer,
    invoiceCustomApprovalTypeUsers,
    invoiceCustomApprovalStatuses,
    customInvoice,
    customIncomingInvoice,
    packingListsStatuses,
    financePayments,
    bankInstruction,
    invoiceLogoIndex,
    qrScanner,
    endorsement,
    landingDeadline,
    purchasingWaybillReport,
    purchasingInvoiceReport,
    purchasingInstructionReport,
    fireReport,
    supplierCompanyEdit,
    WorkmanshipReport,
    EscReport,
    SupplierDeadlineReport,
    SupplierPerformanceReport
  },
  plugins: []
});

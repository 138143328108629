import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    base: '/',
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            mode: "history",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/Dashboard.vue")
                },
                // PLAY GROUND
                {
                    path: "/test",
                    name: "test",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import("@/view/pages/test/Test"),
                },
                // START OF SAMPLE(NUMUNE) MODULE
                {
                    path: "/samples/list",
                    name: "sample",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import("@/view/pages/samples/Samples.vue")
                },
                {
                    path: "/samples/create-meeting-list",
                    name: "sample.create.meeting-list",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import("@/view/pages/samples/create-sample-from-meeting/ListMeeting")
                },
                {
                    path: "/profile/edit",
                    name: "profile.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/profile/edit/ProfileEdit")
                },
                {
                    path: "/profile/change-password",
                    name: "profile.change-password",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/profile/edit/ChangePassword")
                },
                {
                    path: "/samples/create-sample/meeting/:id/detail",
                    name: "sample.create.meeting.detail",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import("@/view/pages/samples/create-sample-from-meeting/MeetingDetail")
                },
                {
                    path: "/samples/tasks",
                    name: "sample.task.index",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/pages/samples/task/TaskIndex'),
                },
                {
                    path: "/samples/:id/edit",
                    name: "sample.product.edit",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/pages/samples/edit/SampleProductEdit'),
                },
                {
                    path: "/samples/:id/download-pdf",
                    name: "sample.product.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/SamplePDF'),
                },
                {
                    path: "/samples/create-original",
                    name: "sample.create.original-sample",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/pages/samples/original-sample/CreateOriginalSample'),
                },
                // END OF SAMPLE(NUMUNE) MODULE

                // START OF MEETING(TOPLANTI) MODULE
                {
                    path: "/meeting/create-meeting",
                    name: "meeting.create_meeting_normal",
                    meta: {
                        parent: 'meeting'
                    },
                    component: () => import('@/view/pages/meeting/create/CreateMeeting'),
                },
                // END OF MEETING(TOPLANTI) MODULE

                // START OF REQUEST FABRIC AND ACCESSORY MODULE
                {
                    path: "/samples/:id/edit/:requestType/request/",
                    name: "sample.request",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/pages/samples/edit/SampleProductEdit'),
                },
                {
                    path: "/samples/:id/edit/:requestType/request/:fabricRequestId/fabric",
                    name: "sample.request.fabric",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/pages/samples/edit/SampleProductEdit'),
                },
                {
                    path: "/samples/:id/edit/:requestType/request/:accessoryRequestId/accessory/:itemId",
                    name: "sample.request.accessory",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/pages/samples/edit/SampleProductEdit'),
                },
                {
                    path: "/samples/:id/yarn/download-pdf",
                    name: "sample.yarn.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/YarnPDF'),
                },
                {
                    path: "/samples/:id/knitting/download-pdf",
                    name: "sample.knitting.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/KnittingPDF'),
                },
                {
                    path: "/samples/:id/press/download-pdf",
                    name: "sample.press.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/PressPDF'),
                },
                {
                    path: "/samples/:id/paint/download-pdf",
                    name: "sample.paint.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/PaintPDF'),
                },
                {
                    path: "/samples/:id/ready-made/download-pdf",
                    name: "sample.ready_made.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/ReadyMadePDF'),
                },
                {
                    path: "/samples/:id/raw-fabric/download-pdf",
                    name: "sample.raw-fabric.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/RawFabricPDF'),
                },
                {
                    path: "/samples/:id/extra-process/download-pdf",
                    name: "sample.extra-process.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/ExtraProcessPDF'),
                },
                {
                    path: "/samples/:id/accessory/download-pdf",
                    name: "sample.accessory.download.pdf",
                    meta: {
                        parent: 'sample'
                    },
                    component: () => import('@/view/templates/requestFabricAndAccessory/AccessoryPDF'),
                },
                // END OF REQUEST FABRIC AND ACCESSORY MODULE


                // START OF WAYBILL(İRSALİYE) MODULE
                {
                    path: "/order-details/:id/:type/:detailId/:sizeAndColorId?",
                    name: "waybill.detail.index",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/WaybillSharedIndex'),
                },
                {
                    path: "/waybill/list",
                    name: "waybill.index",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/WaybillIndex'),
                },
                {
                    path: "/waybill/logo/list",
                    name: "waybill.logo.index",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/WaybillLogoIndex'),
                },
                {
                    path: "/waybill/logo/:id",
                    name: "waybill.logo.entry",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/entry/NewLogoWaybillEntry'),
                },
                {
                    path: "/waybill/entry/:type/new",
                    name: "waybill.entry.new",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/entry/NewWaybillEntry'),
                },
                {
                    path: "/waybill/entry/:id/edit",
                    name: "waybill.entry.edit",
                    component: () => import('@/view/pages/waybill/entry/NewWaybillEntry'),
                },
                {
                    path: "/waybill/:id/detail",
                    name: "waybill.detail",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/detail/WaybillDetail'),
                },
                {
                    path: "/waybill/exit/new",
                    name: "waybill.exit.new",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/exit/NewWaybillExit'),
                },
                {
                    path: "/waybill/exit/:id/edit",
                    name: "waybill.exit.edit",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/pages/waybill/exit/NewWaybillExit'),
                },
                {
                    path: "/waybill/:id/download-pdf",
                    name: "waybill.download.pdf",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/templates/WaybillPDF'),
                },
                {
                    path: "/waybill/tag/:id/download-pdf",
                    name: "waybill.tag.download.pdf",
                    meta: {
                        parent: 'waybill'
                    },
                    component: () => import('@/view/templates/WaybillTagPDF'),
                },

                // END OF WAYBILL(İRSALİYE) MODULE


                // START OF MODEL HOUSE(MODELHANE) MODULE
                {
                    path: "/model-house",
                    name: "model_house.index",
                    meta: {
                        parent: 'modelHouse'
                    },
                    component: () => import('@/view/pages/model-house/ModelHouseIndex'),
                },
                {
                    path: "/model-house/entry",
                    name: "model_house.entry",
                    meta: {
                        parent: 'modelHouse'
                    },
                    component: () => import('@/view/pages/model-house/ModelHouseEntry'),
                },
                {
                    path: "/model-house/:id/tasks",
                    name: "model_house.task",
                    meta: {
                        parent: 'modelHouse'
                    },
                    component: () => import('@/view/pages/model-house/ModelHouseEntry'),
                },
                {
                    path: "/model-house/tasks",
                    name: "model_house.tasks",
                    meta: {
                        parent: 'modelHouse'
                    },
                    component: () => import('@/view/pages/model-house/ModelHouseTasks'),
                },
                {
                    path: "/model-house/:model_house_id/sample/:id/task/:model_house_task_status/",
                    name: "model_house.task.action",
                    meta: {
                        parent: 'modelHouse'
                    },
                    component: () => import('@/view/pages/samples/edit/SampleProductEdit'),
                },
                // END OF MODEL HOUSE(MODELHANE) MODULE


                // START OF QR SCANNER MODULE

                {
                    path: "/qr-scanner",
                    name: "qr_scanner.index",
                    component: () => import('@/view/pages/qr-scanner/QRScanner'),
                },

                // END OF QR SCANNER MODULE

                // START OF SETTING ROUTES
                {
                    path: "/setting/notification",
                    name: "setting.notification",
                    component: () => import('@/view/pages/setting/Notification'),
                },
                // END OF SETTING ROUTES

                // GENERAL ROUTES
                {
                    path: "/:parent/company/create",
                    name: "general.company.create",
                    meta: {
                        parent: ''
                    },
                    component: () => import("@/view/pages/general/company/GeneralCompanyCreate"),
                },

                //START OF ESC
                {
                    path: "/esc",
                    name: "esc-meeting.index",
                    meta: {
                        parent: 'escTable'
                    },
                    component: () => import('@/view/pages/esc/EscIndex'),
                },
                {
                    path: "/esc/entry/:type/new",
                    name: "esc.entry.new",
                    meta: {
                        parent: 'escTable'
                    },
                    component: () => import('@/view/pages/esc/entry/NewEscEntry'),
                },
                {
                    path: "/esc/entry/:id/edit",
                    name: "esc.entry.edit",
                    meta: {
                        parent: 'escTable'
                    },
                    component: () => import('@/view/pages/esc/entry/NewEscEntry'),
                },
                //START OF TIME SHEET
                {
                    path: "/time-sheet",
                    name: "time-sheet.index",
                    meta: {
                        parent: 'timeSheet'
                    },
                    component: () => import('@/view/pages/time-sheet/TimeSheetIndex'),
                },
                {
                    path: "/time-sheet/create",
                    name: "time-sheet.create",
                    meta: {
                        parent: 'timeSheet'
                    },
                    component: () => import('@/view/pages/time-sheet/CreateOrEditTimeSheet'),
                },
                {
                    path: "/time-sheet/:id/edit",
                    name: "time-sheet.edit",
                    meta: {
                        parent: 'timeSheet'
                    },
                    component: () => import('@/view/pages/time-sheet/CreateOrEditTimeSheet'),
                },

                //START OF CSR
                {
                    path: "/csr",
                    name: "csr.index",
                    meta: {
                        parent: 'csr'
                    },
                    component: () => import('@/view/pages/csr/Index'),
                },
                {
                    path: "/csr/add",
                    name: "csr.new",
                    meta: {
                        parent: 'csr'
                    },
                    component: () => import('@/view/pages/csr/AddEditCsr'),
                },
                {
                    path: "/csr/:id/edit",
                    name: "csr.edit",
                    meta: {
                        parent: 'csr'
                    },
                    component: () => import('@/view/pages/csr/AddEditCsr'),
                },

                // START OF ORDER(SİPARİŞ)

                {
                    path: "/orders/list",
                    name: "order.index",
                    meta: {
                        parent: 'order'
                    },
                    component: () => import('@/view/pages/order/OrderIndex'),
                },
                {
                    path: "/orders/:type/create",
                    name: "order.create",
                    meta: {
                        parent: 'order'
                    },
                    component: () => import('@/view/pages/order/CreateNewOrder'),
                },
                {
                    path: "/order/type/:type/:id/edit",
                    name: "order.edit",
                    meta: {
                        parent: 'order'
                    },
                    props: true,
                    component: () => import('@/view/pages/order/CreateNewOrder'),
                },
                // END OF ORDER(SİPARİŞ)

                // START OF FOLLOWING PRODUCTION
                {
                    path: "/following-production/calendar",
                    name: "following_production.index",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-production/FollowingProductionCalendarView')
                },
                {
                    path: "/following-production/table",
                    name: "following_production.index.table",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-production/FollowingProductionTableView'),
                },
                {
                    path: "/following-production/order",
                    name: "following_production.index.order",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-production/FollowingProductionOrderView'),
                },
                {
                    path: "/following-production/:id/edit",
                    name: "following_production.edit",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-production/EditFollowingProduction'),
                },
                {
                    path: "/following-production/packing-list",
                    name: "following_production.packing_list.index",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-production/PackingListIndex'),
                },

                {
                    path: "/following-production/packing-list/:packing_id/edit",
                    name: "following_production.packing_list.edit",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-production/EditFollowingProduct'),
                },
                {
                    path: "/following-production/tasks",
                    name: "following_production.tasks.index",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-production/tasks/FollowingProductionTasks'),
                },
                // END OF FOLLOWING PRODUCTION

                // START OF FOLLOWING EXPORT
                {
                    path: "/following-export/list",
                    name: "following_export.index",
                    meta: {
                        parent: 'exportFollow'
                    },
                    component: () => import('@/view/pages/following-export/FollowingExportIndex'),
                },
                {
                    path: "/following-export/packing-list",
                    name: "following_export.packing_list.index",
                    meta: {
                        parent: 'exportFollow'
                    },
                    component: () => import('@/view/pages/following-export/PackingListIndex'),
                },
                {
                    path: "/following-export/packing-list/:packing_id/edit",
                    name: "following_export.packing_list.edit",
                    meta: {
                        parent: 'dreadFollow'
                    },
                    component: () => import('@/view/pages/following-export/EditFollowingExport'),
                },
                {
                    path: "/following-export/:id",
                    name: "following_export.packing_list.detail",
                    meta: {
                        parent: 'exportFollow'
                    },
                    component: () => import('@/view/pages/following-export/PackingListDetail'),
                },
                {
                    path: "/following-export/task/list",
                    name: "following_export.tasks.list",
                    meta: {
                        parent: 'exportFollow'
                    },
                    component: () => import('@/view/pages/following-export/tasks/FollowingExportTasks'),
                },
                // END OF FOLLOWING PRODUCTION

                // START OF INVOICE
                {
                    path: "/invoice/incoming/list",
                    name: "invoice.index",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/InvoiceIndex'),
                },
                {
                    path: "/invoice/outgoing/list",
                    name: "invoice.outgoing",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/outgoing/OutgoingIndex'),
                },
                {
                    path: "/invoice/:id/:type/edit/",
                    name: "invoice.edit_outgoing",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/outgoing/EditOutgoing'),
                },
                {
                    path: "/invoice/:id/details",
                    name: "invoice.outgoing_details",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/outgoing/OutgoingDetails'),
                },
                {
                    path: "/invoice/add/",
                    name: "invoice.add_outgoing",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/outgoing/AddOutgoing'),
                },
                {
                    path: "/invoice/tasks",
                    name: "invoice.task.tasks",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/task/InvoiceTasks'),
                },
                {
                    path: "/invoice/:id/detail/:type?",
                    name: "invoice.details",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/InvoiceDetails'),
                },
                {
                    path: "/invoice/:id/edit-v2",
                    name: "invoice.details-v2",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/V2InvoiceEdit'),
                },
                {
                    path: "/invoice/:id/task/:type?",
                    name: "invoice.task.action",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/task/TaskAction'),
                },
                {
                    path: "/invoice/create",
                    name: "invoice.create",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/InvoiceCreateEdit'),
                },
                {
                    path: "/invoice/:id/edit",
                    name: "invoice.edit",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/InvoiceCreateEdit'),
                },
                {
                    path: "/invoice/:id/edit-logo",
                    name: "invoice.edit.logo",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/InvoiceCreateEditLogo'),
                },

                {
                    path: "/invoice/logo/list",
                    name: "invoice.logo.index",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/InvoiceLogoIndex'),
                },

                {
                    path: "/invoice/logo/error-list",
                    name: "invoice.logo_error.index",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/incoming/InvoiceErrorLogoIndex'),
                },
                //finance payments modules
                {
                    path: "/invoice/approved-invoices",
                    name: "invoice.approved_invoices.index",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/finance-payments/ApprovedInvoicesIndex'),
                },
                {
                    path: "/finance-payments",
                    name: "finance_payments.index",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/finance-payments/FinancePaymentIndex'),
                },
                {
                    path: "/finance-payments/:id/edit",
                    name: "finance_payments.edit",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/finance-payments/FinancePaymentEdit'),
                },
                // bank-instructions modules

                {
                    path: "/bank-instructions",
                    name: "bank_instructions.index",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/bank-instructions/BankInstructionIndex'),
                },
                {
                    path: "/bank-instructions/:id/edit",
                    name: "bank_instructions.edit",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/bank-instructions/BankInstructionCreateEdit'),
                },
                {
                    path: "/bank-instructions/create",
                    name: "bank_instructions.create",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/bank-instructions/BankInstructionCreateEdit'),
                },

                // END OF INVOICE

                // Special / Custom Approved Invoices
                //backkende controllerin isimleri custom ile başlıyor
                {
                    path: "/invoice/invoice-special-approval-tasks",
                    name: "invoice.special_approved_invoices.index",
                    meta: {
                        parent: 'invoice'
                    },
                    component: () => import('@/view/pages/invoice/special-approved-invoices/SpecialApprovedInvoicesIndex'),
                },
                // START OF PANEL MANAGEMENT
                {
                    path: "/management/currencies",
                    name: "management.currency_units.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/currency/CurrencyIndex"),
                },
                {
                    path: "/management/stock-cards",
                    name: "management.stock_card.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/stock-card/StockCardIndex"),
                },
                // Management - Units
                {
                    path: "/management/unit",
                    name: "management.unit.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/unit/UnitIndex"),
                },
                // Management - Vat Rates
                {
                    path: "/management/vat-rates",
                    name: "management.vatRate.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/vat_rate/VatRateIndex"),
                },
                {
                    path: "/management/vat-rates/create",
                    name: "management.vatRate.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/vat_rate/VatRateCreateEdit"),
                },
                {
                    path: "/management/vat-rates/:id/edit",
                    name: "management.vatRate.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/vat_rate/VatRateCreateEdit"),
                },
                // Management - Expenses
                {
                    path: "/management/expenses",
                    name: "management.expense.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/expense/ExpenseIndex"),
                },
                {
                    path: "/management/expenses/create",
                    name: "management.expense.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/expense/ExpenseCreateEdit"),
                },
                {
                    path: "/management/expenses/:id/edit",
                    name: "management.expense.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/expense/ExpenseCreateEdit"),
                },
                // Managament - Invoice Custom Approval Types
                {
                    path: "/management/invoice-custom-approval-types",
                    name: "management.invoice_custom_approval_type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice-custom-approval-type/InvoiceCustomApprovalTypeIndex"),
                },
                {
                    path: "/management/invoice-custom-approval-types/:id/edit",
                    name: "management.invoice_custom_approval_type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice-custom-approval-type/InvoiceCustomApprovalTypeCreateEdit"),
                },
                {
                    path: "/management/invoice-custom-approval-types/create",
                    name: "management.invoice_custom_approval_type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice-custom-approval-type/InvoiceCustomApprovalTypeCreateEdit"),
                },
                // Managament - Invoice Custom Approval Users
                {
                    path: "/management/invoice-custom-approval-users",
                    name: "management.invoice_custom_approval_users.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice-custom-approval-users/InvoiceCustomApprovalUsersIndex"),
                },
                {
                    path: "/management/invoice-custom-approval-users/create",
                    name: "management.invoice_custom_approval_users.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice-custom-approval-users/InvoiceCustomApprovalUsersCreateEdit"),
                },
                {
                    path: "/management/invoice-custom-approval-users/:id/edit",
                    name: "management.invoice_custom_approval_users.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice-custom-approval-users/InvoiceCustomApprovalUsersCreateEdit"),
                },
                // Managament - Invoice Custom Approval Users
                {
                    path: "/management/invoice-custom-approval-statuses",
                    name: "management.invoice_custom_approval_statuses.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice-custom-approval-statuses/InvoiceCustomApprovalStatusesIndex"),
                },
                // {
                //     path: "/management/invoice-custom-approval-statuses/create",
                //     name: "management.invoice_custom_approval_statuses.create",
                //     meta: {
                //         parent: 'management'
                //     },
                //     component: () => import("@/view/pages/panel-management/invoice-custom-approval-statuses/InvoiceCustomApprovalStatusesCreateEdit"),
                // },
                // {
                //     path: "/management/invoice-custom-approval-statuses/:id/edit",
                //     name: "management.invoice_custom_approval_statuses.edit",
                //     meta: {
                //         parent: 'management'
                //     },
                //     component: () => import("@/view/pages/panel-management/invoice-custom-approval-statuses/InvoiceCustomApprovalStatusesCreateEdit"),
                // },
                // Management - Accessories
                {
                    path: "/management/accessories",
                    name: "management.accessory.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/accessory/AccessoryIndex"),
                },
                {
                    path: "/management/accessories/create",
                    name: "management.accessory.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/accessory/AccessoryCreateEdit"),
                },
                {
                    path: "/management/accessories/:id/edit",
                    name: "management.accessory.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/accessory/AccessoryCreateEdit"),
                },
                // Management - Accessories
                {
                    path: "/management/fabrics",
                    name: "management.fabric.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric/FabricIndex"),
                },
                {
                    path: "/management/fabrics/create",
                    name: "management.fabric.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric/FabricCreateEdit"),
                },
                {
                    path: "/management/fabrics/:id/edit",
                    name: "management.fabric.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric/FabricCreateEdit"),
                },

                // Management - Instruction Type
                {
                    path: "/management/instruction-type",
                    name: "management.instruction_type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/instruction_type/InstructionTypeIndex"),
                },
                {
                    path: "/management/instruction-type/create",
                    name: "management.instruction_type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/instruction_type/InstructionTypeCreateEdit"),
                },
                {
                    path: "/management/instruction-type/:id/edit",
                    name: "management.instruction_type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/instruction_type/InstructionTypeCreateEdit"),
                },
                {
                    path: "/management/body-size",
                    name: "management.body-size.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/body-size/BodySizeIndex"),
                },
                {
                    path: "/management/body-size/create",
                    name: "management.body-size.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/body-size/BodySizeCreateEdit"),
                },
                {
                    path: "/management/body-size/:id/edit",
                    name: "management.body-size.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/body-size/BodySizeCreateEdit"),
                },

                {
                    path: "/management/product-model",
                    name: "management.product-model.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/product-model/ProductModelIndex"),
                },
                {
                    path: "/management/product-model/create",
                    name: "management.product-model.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/product-model/ProductModelCreateEdit"),
                },
                {
                    path: "/management/product-model/:id/edit",
                    name: "management.product-model.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/product-model/ProductModelCreateEdit"),
                },
                {
                    path: "/management/fabric-type",
                    name: "management.fabric-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-type/FabricTypeIndex"),
                },
                {
                    path: "/management/fabric-type/create",
                    name: "management.fabric-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-type/FabricTypeCreateEdit"),
                },
                {
                    path: "/management/fabric-type/:id/edit",
                    name: "management.fabric-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-type/FabricTypeCreateEdit"),
                },
                /*
                                {
                                    path: "/management/sample-type",
                                    name: "management.sample-type.index",
                                    meta: {
                                        parent: 'management'
                                    },
                                    component: () => import("@/view/pages/panel-management/sample-type/SampleTypeIndex"),
                                },*/
                {
                    path: "/management/sample-type/create",
                    name: "management.sample-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/sample-type/SampleTypeCreateEdit"),
                },
                {
                    path: "/management/sample-type/:id/edit",
                    name: "management.sample-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/sample-type/SampleTypeCreateEdit"),
                },

                {
                    path: "/management/press-type",
                    name: "management.press-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/press-type/PressTypeIndex"),
                },
                {
                    path: "/management/press-type/create",
                    name: "management.press-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/press-type/PressTypeCreateEdit"),
                },
                {
                    path: "/management/press-type/:id/edit",
                    name: "management.press-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/press-type/PressTypeCreateEdit"),
                },

                {
                    path: "/management/planter",
                    name: "management.planter.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/planter/PlanterIndex"),
                },
                {
                    path: "/management/planter/create",
                    name: "management.planter.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/planter/PlanterCreateEdit"),
                },
                {
                    path: "/management/planter/:id/edit",
                    name: "management.planter.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/planter/PlanterCreateEdit"),
                },
                {
                    path: "/management/invoice-type",
                    name: "management.invoice-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_type/InvoiceTypeIndex"),
                },
                {
                    path: "/management/transferred-invoices",
                    name: "management.invoice-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/transferred_invoice/TransferredInvoice"),
                },
                {
                    path: "/management/invoice-type/create",
                    name: "management.invoice-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_type/InvoiceTypeCreateEdit"),
                },
                {
                    path: "/management/invoice-type/:id/edit",
                    name: "management.invoice-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_type/InvoiceTypeCreateEdit"),
                },
                {
                    path: "/management/invoice-receive-type",
                    name: "management.invoice-receive-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_receive/InvoiceReceiveTypeIndex"),
                },
                {
                    path: "/management/invoice-receive-type/create",
                    name: "management.invoice-receive-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_receive/InvoiceReceiveTypeCreateEdit"),
                },
                {
                    path: "/management/invoice-receive-type/:id/edit",
                    name: "management.invoice-receive-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_receive/InvoiceReceiveTypeCreateEdit"),
                },
                {
                    path: "/management/invoice-category",
                    name: "management.invoice-category.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_category/InvoiceCategoryIndex"),
                },
                {
                    path: "/management/invoice-category/create",
                    name: "management.invoice-category.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_category/InvoiceCategoryCreateEdit"),
                },
                {
                    path: "/management/invoice-category/:id/edit",
                    name: "management.invoice-category.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/invoice_category/InvoiceCategoryCreateEdit"),
                },
                {
                    path: "/management/vat-exception",
                    name: "management.vat-exception.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/vat_exception/VatExceptionIndex"),
                },
                {
                    path: "/management/vat-deduct",
                    name: "management.vat-deduct.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/vat_deduct/VatDeductIndex"),
                },
                {
                    path: "/management/fabric-composition",
                    name: "management.fabric-composition.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-composition/FabricCompositionIndex"),
                },
                {
                    path: "/management/fabric-composition/create",
                    name: "management.fabric-composition.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-composition/FabricCompositionCreateEdit"),
                },
                {
                    path: "/management/fabric-composition/:id/edit",
                    name: "management.fabric-composition.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-composition/FabricCompositionCreateEdit"),
                },
                {
                    path: "/management/logo-accounts",
                    name: "management.logo_accounts.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/logo_accounts/LogoAccountsIndex"),
                },
                {
                    path: "/management/translation",
                    name: "management.translations.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/translation/TranslationIndex"),
                },
                {
                    path: "/management/translation/:id/edit",
                    name: "management.translations.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/translation/TranslationEdit"),
                },
                {
                    path: "/management/store-management",
                    name: "management.store-management.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/store-management/StoreManagementIndex"),
                },
                {
                    path: "/management/store-management/:id/edit",
                    name: "management.store-management.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/store-management/StoreManagementCreateEdit"),
                },
                {
                    path: "/management/store-management/create",
                    name: "management.store-management.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/store-management/StoreManagementCreateEdit"),
                },
                {
                    path: "/management/waybill-type",
                    name: "management.waybill-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/waybill-type/WaybillTypeIndex"),
                },
                {
                    path: "/management/waybill-type/:id/edit",
                    name: "management.waybill-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/waybill-type/WaybillTypeCreateEdit"),
                },
                {
                    path: "/management/waybill-type/create",
                    name: "management.waybill-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/waybill-type/WaybillTypeCreateEdit"),
                },
                {
                    path: "/management/users",
                    name: "management.users.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/users/UsersIndex"),
                },
                {
                    path: "/management/users/:id/edit",
                    name: "management.users.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/users/UsersCreateEdit"),
                },
                {
                    path: "/management/users/create",
                    name: "management.users.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/users/UsersCreateEdit"),
                },
                {
                    path: "/management/roles-scope",
                    name: "management.roles-scope.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/roles-scope/RolesScopeIndex"),
                },
                {
                    path: "/management/roles-scope/:id/edit",
                    name: "management.roles-scope.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/roles-scope/RolesScopeCreateEdit"),
                },

                {
                    path: "/management/roles-scope/create",
                    name: "management.roles-scope.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/roles-scope/RolesScopeCreateEdit"),
                },
                {
                    path: "/management/supplier-company",
                    name: "management.supplier-company.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/supplier-company/SupplierCompanyIndex"),
                },
                {
                    path: "/management/supplier-company/create",
                    name: "management.supplier-company.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/supplier-company/SupplierCompanyCreateEdit"),
                },
                {
                    path: "/management/supplier-company/:id/edit",
                    name: "management.supplier-company.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/supplier-company/SupplierCompanyCreateEdit"),
                },
                {
                    path: "/management/fabric-yarn-type",
                    name: "management.fabric-yarn-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-yarn-type/FabricYarnTypeIndex"),
                },
                {
                    path: "/management/fabric-yarn-type/create",
                    name: "management.fabric-yarn-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-yarn-type/FabricYarnTypeCreateEdit"),
                },
                {
                    path: "/management/fabric-yarn-type/:id/edit",
                    name: "management.fabric-yarn-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-yarn-type/FabricYarnTypeCreateEdit"),
                },
                {
                    path: "/management/fabric-addition",
                    name: "management.fabric-addition.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-addition/FabricAdditionIndex"),
                },
                {
                    path: "/management/fabric-addition/create",
                    name: "management.fabric-addition.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-addition/FabricAdditionCreateEdit"),
                },
                {
                    path: "/management/fabric-addition/:id/edit",
                    name: "management.fabric-addition.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-addition/FabricAdditionCreateEdit"),
                },
                {
                    path: "/management/fabric-knit-type",
                    name: "management.fabric-knit-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-knit-type/FabricKnitTypeIndex"),
                },
                {
                    path: "/management/fabric-knit-type/create",
                    name: "management.fabric-knit-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-knit-type/FabricKnitTypeCreateEdit"),
                },
                {
                    path: "/management/fabric-knit-type/:id/edit",
                    name: "management.fabric-knit-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-knit-type/FabricKnitTypeCreateEdit"),
                },
                {
                    path: "/management/fabric-process",
                    name: "management.fabric-process.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-process/FabricProcessIndex"),
                },
                {
                    path: "/management/fabric-process/create",
                    name: "management.fabric-process.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-process/FabricProcessCreateEdit"),
                },
                {
                    path: "/management/fabric-process/:id/edit",
                    name: "management.fabric-process.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-process/FabricProcessCreateEdit"),
                },
                {
                    path: "/management/product-process",
                    name: "management.product-process.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/product-process/ProductProcessIndex"),
                },
                {
                    path: "/management/product-process/create",
                    name: "management.product-process.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/product-process/ProductProcessCreateEdit"),
                },
                {
                    path: "/management/product-process/:id/edit",
                    name: "management.product-process.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/product-process/ProductProcessCreateEdit"),
                },
                {
                    path: "/management/general-settings",
                    name: "management.general-setting",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/general-setting/GeneralSettingIndex"),
                },

                // Management - Packing List Statuses

                {
                    path: "/management/packing-list-statuses",
                    name: "management.packing-list-statuses.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/packing-list-statuses/PackingListStatuses"),
                },
                {
                    path: "/management/packing-list-statuses/:id/edit",
                    name: "management.packing-list-statuses.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/packing-list-statuses/PackingListStatusesCreateEdit"),
                },

                {
                    path: "/management/packing-list-statuses/create",
                    name: "management.packing-list-statuses.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/packing-list-statuses/PackingListStatusesCreateEdit"),
                },

                // END OF PANEL MANAGEMENT

                // START REPORTS
                {
                    path: "/report",
                    name: "report.user",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/UsersReport'),
                },
                {
                    path: "/report/endorsement-report",
                    name: "report.endorsement_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/EndorsementReport'),
                },
                {
                    path: "/report/purchasing-waybill-report",
                    name: "report.purchasing_waybill_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/PurchasingWaybillReport'),
                },
                {
                    path: "/report/purchasing-invoice-report",
                    name: "report.purchasing_invoice_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/PurchasingInvoiceReport'),
                },
                {
                    path: "/report/purchasing-instruction-report",
                    name: "report.purchasing_instruction_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/PurchasingInstructionReport'),
                },
                {
                    path: "/report/loading-deadline-report",
                    name: "report.loading_deadline_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/LoadingDeadlineReport/LoadingDeadlineReport'),
                },
                {
                    path: "/report/second-quantity-report",
                    name: "report.second-quantity-report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/SecondQualityReport/SecondQualityReport'),
                },
                {
                    path: "/report/profit-and-loss-report",
                    name: "report.profit-and-loss-report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/ProfitAndLossReport/ProfitAndLossReport'),
                },
                {
                    path: "/report/slaughter-report",
                    name: "report.slaughter-report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/SlaughterReport/SlaughterReport'),
                },
                {
                    path: "/report/workmanship-report",
                    name: "report.workmanship-report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/WorkmanshipReport/WorkmanshipReport'),
                },
                {
                    path: "/report/esc-report",
                    name: "report.esc-report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/EscReport/EscReport'),
                },                
                {
                    path: "/report/fire-report",
                    name: "report.fire_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/FireReport/FireReport'),
                },
                {
                    path: "/report/supplier-performance-report",
                    name: "report.supplier_performance_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/SupplierPerformance/SupplierPerformanceReport'),
                },
                {
                    path: "/report/supplier-deadline-report",
                    name: "report.supplier_deadline_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/SupplierDeadline/SupplierDeadlineReport'),
                },
                {
                    path: "/report/supplier-success-report",
                    name: "report.supplier_success_report",
                    meta: {
                        parent: 'report'
                    },
                    component: () => import('@/view/pages/report/SupplierSuccess/SupplierSuccessReport'),
                },
                // END REPORTS
                {
                    path: "/management/teams",
                    name: "management.teams.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/teams/TeamsIndex"),
                },
                {
                    path: "/management/teams/:id/edit",
                    name: "management.teams.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/teams/TeamsCreateEdit"),
                },
                {
                    path: "/management/teams/create",
                    name: "management.teams.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/teams/TeamsCreateEdit"),
                },

                {
                    path: "/management/supplier-type",
                    name: "management.supplier-type.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/supplier-type/SupplierTypeIndex"),
                },
                {
                    path: "/management/supplier-type/:id/edit",
                    name: "management.supplier-type.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/supplier-type/SupplierTypeCreateEdit"),
                },
                {
                    path: "/management/supplier-type/create",
                    name: "management.supplier-type.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/supplier-type/SupplierTypeCreateEdit"),
                },
                {
                    path: "/management/csr-process",
                    name: "management.csr-process.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/csr-process/CsrProcessIndex"),
                },
                {
                    path: "/management/csr-process/:id/edit",
                    name: "management.csr-process.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/csr-process/CsrProcessCreateEdit"),
                },
                {
                    path: "/management/csr-process/create",
                    name: "management.csr-process.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/csr-process/CsrProcessCreateEdit"),
                },
                {
                    path: "/management/companies",
                    name: "management.companies.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/companies/CompaniesIndex"),
                },
                {
                    path: "/management/companies/:id/edit",
                    name: "management.companies.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/companies/CompaniesCreateEdit"),
                },
                {
                    path: "/management/companies/create",
                    name: "management.companies.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/companies/CompaniesCreateEdit"),
                },
                // For Invoice Expense  Permissions
                {
                    path: "/management/expense-permission",
                    name: "management.expense-permission.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/expense-permission/ExpensePermissionIndex"),
                },
                {
                    path: "/management/expense-permission/:id/edit",
                    name: "management.expense-permission.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/expense-permission/ExpensePermissionCreateEdit"),
                },
                {
                    path: "/management/expense-permission/create",
                    name: "management.expense-permission.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/expense-permission/ExpensePermissionCreateEdit"),
                },
                // For Invoice Fabric Permissions
                {
                    path: "/management/fabric-permission",
                    name: "management.fabric-permission.index",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-permission/FabricPermissionIndex"),
                },
                {
                    path: "/management/fabric-permission/:id/edit",
                    name: "management.fabric-permission.edit",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-permission/FabricPermissionCreateEdit"),
                },
                {
                    path: "/management/fabric-permission/create",
                    name: "management.fabric-permission.create",
                    meta: {
                        parent: 'management'
                    },
                    component: () => import("@/view/pages/panel-management/fabric-permission/FabricPermissionCreateEdit"),
                },
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/auth/Register")
                }
            ]
        },
        {
            // the 403 route, when access denied
            path: "/403",
            name: "403",
            component: () => import("@/view/pages/error/403.vue")
        },
        {
            // the 500 route, when access denied
            path: "/500",
            name: "500",
            component: () => import("@/view/pages/error/500.vue")
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/404.vue")
        }
    ]
});

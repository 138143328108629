export default {
    methods: {
        isEmail(value) {
            if (value === null || value === undefined) {
                return false;
            }
            if (value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                return true;
            } else {
                return false;
            }
        },
        covertToTranslationList(object, translationsName, keys) {
            let languages = JSON.parse(this.systemLanguages);
            let result = [];
            for (let i = 0; i < languages.length; i++) {
                result.push({
                    language_code: languages[i].code,
                    translations: {}
                });
                for (let key in keys) {
                    let list = object[translationsName];
                    if (list) {
                        if (!Array.isArray(keys)) {
                            result[i].translations[key] = list[i][keys[key]];
                        } else {
                            result[i].translations[[keys[key]]] = list[i][keys[key]];
                        }
                    } else {
                        if (!Array.isArray(keys)) {
                            result[i].translations[key] = keys[key];
                        } else {
                            result[i].translations[keys[key]] = null;
                        }
                    }
                }
            }
            delete object[translationsName];
            return {
                ...object,
                converted: true,
                [translationsName]: result,
            };
        },
        convertTranslationObjectToNormal(object, translationName){
            let temp = [];
            for(let i = 0; i < object[translationName].length; i++){
                temp.push({
                    ...object[translationName][i].translations
                });
            }

            object[translationName] = [...temp];
            return object;
        }
    }
};
import ApiService from "@/core/services/api.service";
import {LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import moment from 'moment';
import {GET_SAMPLE_MENU_BADGE_NO} from "@/core/services/store/menu.module";


const BASE = 'SAMPLE/CREATE/MEETING/DETAIL';

// GETTERS
export const MEETING = `${BASE}/MEETING`;
export const LOADING = `${BASE}/LOADING`;
export const ERROR = `${BASE}/ERROR`;
export const SELECTED_USER = `${BASE}/SELECTED_USER`;
export const SAMPLE_USERS = `${BASE}/USERS`;
export const SELECTED_PRODUCTS = `${BASE}/SELECTED_PRODUCTS`;

// MUTATIONS
export const SET_MEETING = `${BASE}/M/MEETING`;
export const SET_LOADING = `${BASE}/M/LOADING`;
export const SET_ERROR = `${BASE}/M/ERROR`;
export const SET_SELECTED_USER = `${BASE}/M/SELECTED_USER`;
export const SET_SAMPLE_USERS = `${BASE}/M/USERS`;
export const SET_SELECTED_PRODUCTS = `${BASE}/M/SELECTED_PRODUCTS`;
export const RESET_STATE = `${BASE}/M/RESET_STATE`;

// ACTIONS
export const GET_MEETING_DETAIL = `${BASE}/A/GET_MEETING_DETAIL`;
export const CREATE_SAMPLE = `${BASE}/A/CREATE_SAMPLE`;
export const GET_SAMPLE_USERS = `${BASE}/A/GET_SAMPLE_USERS`;


const state = {
    meeting: {
        name: '',
        date_hour: moment(),
        meeting_products: [],

    },
    loading: false,
    error: null,
    selectedUser: null,
    users: [],
    selectedProducts: [],
};

const getters = {
    [MEETING]: (state) => {
        return state.meeting;
    },
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SELECTED_USER]: (state) => {
        return state.selectedUser;
    },
    [SAMPLE_USERS]: (state) => {
        return state.users;
    },
    [SELECTED_PRODUCTS]: (state) => {
        return state.selectedProducts;
    },


};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload;
    },
    [SET_MEETING]: (state, payload) => {
        payload.date_hour = moment(payload.date_hour, LARAVEL_DATE_TIME_FORMAT);

        state.meeting = payload;
    },
    [SET_SELECTED_USER]: (state, payload) => {
        state.selectedUser = payload;
    },
    [SET_SAMPLE_USERS]: (state, payload) => {
        state.users = payload;
    },
    [SET_SELECTED_PRODUCTS]: (state, payload) => {
        state.selectedProducts = payload;
    },
    [RESET_STATE]: (state) => {
        state.meeting = {
            name: '',
                date_hour: moment(),
                meeting_products: [],
        };
        state.selectedUser = null;
        state.selectedProducts = [];
    },

};

const actions = {
    [GET_MEETING_DETAIL]: (context, payload) => {
        context.commit(SET_LOADING, true);
        ApiService.get(`api/meetings/${payload.params.id}`).then((response ) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_MEETING, response.data);
        }).catch((error) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
        });
    },
    [GET_SAMPLE_USERS]: (context) => {
        context.commit(SET_LOADING, true);
        ApiService.get(`api/sample-users`).then((response ) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_SAMPLE_USERS, response.data);
        }).catch((error) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
        });
    },
    [CREATE_SAMPLE]: (context, payload) => {
        context.commit(SET_LOADING, true);

        let promises = [];
        let products = context.state.meeting.meeting_products;
        for(let i = 0; i < context.state.selectedProducts; i++){
            if(context.state.selectedProducts[i] == null) {
                continue;
            }
            let current_sample_type_id = null;
            let current_product_name = null;
            for(let j = 0; j < products.length; j++){
                if(Number(products[j].id) === Number(context.state.selectedProducts[i])){
                    current_sample_type_id = products[j].sample_type_id;
                    current_product_name = products[j].name;
                    break;
                }
            }
            if(current_sample_type_id == null){
                context.commit(ERROR, "general.internal_error_while_submitting");
            }
            let formData = {
                meeting_id: context.state.meeting.id,
                product_id: context.state.selectedProducts[i],
                company_id: context.state.meeting.company_id,
                name: current_product_name,
                order_date: moment().format(LARAVEL_DATE_TIME_FORMAT), // TODO: need to change to meaning full date for order
                sample_type_id: current_sample_type_id,
                user_id: context.state.selectedUser,
            };
            promises.push(ApiService.post(`api/samples`, formData, {
                'Content-Type': 'application/json',
            }));
        }

        Promise.all(promises).then((results) => {
            try{
                payload.onSuccess(payload.self);
                context.commit(RESET_STATE);
                context.dispatch(GET_SAMPLE_MENU_BADGE_NO);
            }catch (e){
                console.log(e);
            }
        }).catch((error) => {
            // context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
        });

    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
import ApiService from "@/core/services/api.service";
import querystring from 'querystring';
import moment from "moment";
import {
    LARAVEL_DATE_FORMAT,
    LARAVEL_DATE_TIME_FORMAT,
    LARAVEL_RAW_DATE_TIME_FORMAT,
} from "@/core/config/constant";

const BASE = 'SAMPLE/TASK/INDEX';

export const LOADING = `${BASE}/LOADING`;
export const ERROR = `${BASE}/ERROR`;
export const TASKS = `${BASE}/TASKS`;
export const FORMATTED_TASK = `${BASE}/FORMATTED_TASK`;
export const START_DATE = `${BASE}/START_DATE`;
export const END_DATE = `${BASE}/END_DATE`;
export const SEARCH = `${BASE}/SEARCH`;
export const PAGE = `${BASE}/PAGE`;
export const PER_PAGE = `${BASE}/PER_PAGE`;
export const TASK_COUNT = `${BASE}/TASK_COUNT`;

// MUTATIONS
export const SET_LOADING = `${BASE}/M/LOADING`;
export const SET_ERROR = `${BASE}/M/ERROR`;
export const SET_TASKS = `${BASE}/M/TASKS`;
export const SET_START_DATE = `${BASE}/M/START_DATE`;
export const SET_END_DATE = `${BASE}/M/END_DATE`;
export const SET_SEARCH = `${BASE}/M/SEARCH`;
export const SET_PER_PAGE = `${BASE}/M/PER_PAGE`;
export const SET_PAGE = `${BASE}/M/PAGE`;
export const RESET_FILTERS = `${BASE}/M/RESET_FILTERS`;
export const SET_TASK_COUNT = `${BASE}/M/TASK_COUNT`;


export const GET_TASKS = `${BASE}/A/TASKS`;
export const GET_TASK_COUNT = `${BASE}/A/GET_TASK_COUNT`;


const state = {
    loading: false,
    error: null,
    tasks: {},
    startDate: moment().subtract(30, 'd'),
    endDate: moment().add(1, 'd'),
    search: '',
    perPage: 25,
    page: 1,
    taskCount: 0,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [TASKS]: (state) => {
        return state.tasks;
    },
    [FORMATTED_TASK]: (state) => {
        let items = [];
        for(let key in state.tasks.data){
            /** @type {Object}*/
            let temp = state.tasks.data[key];
            temp.create_at = moment(temp.created_at, LARAVEL_RAW_DATE_TIME_FORMAT);
            temp.updated_at = moment(temp.updated_at, LARAVEL_RAW_DATE_TIME_FORMAT);
            temp.deleted_at = moment(temp.deleted_at, LARAVEL_RAW_DATE_TIME_FORMAT);
            temp.order_date = moment(temp.order_date, LARAVEL_DATE_TIME_FORMAT);
            temp.date_hour = moment(temp.date_hour, LARAVEL_DATE_TIME_FORMAT);
            items.push(temp);
        }
        return items;
    },
    [START_DATE]: (state) => {
        return state.startDate;
    },
    [END_DATE]: (state) => {
        return state.endDate;
    },
    [SEARCH]: (state) => {
        return state.search;
    },
    [PAGE]: (state) => {
        return state.page;
    },
    [PER_PAGE]: (state) => {
        return state.perPage;
    },
    [TASK_COUNT]: (state) => {
        return state.taskCount;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.loading = payload;
    },
    [SET_TASKS]: (state, payload) => {
        state.tasks = payload;
    },
    [SET_START_DATE]: (state, payload) => {
        state.startDate = payload;
    },
    [SET_END_DATE]: (state, payload) => {
        state.endDate = payload;
    },
    [SET_SEARCH]: (state, payload) => {
        state.search = payload;
    },
    [SET_PER_PAGE]: (state, payload) => {
        state.perPage = payload;
    },
    [SET_PAGE]: (state, payload) => {
        state.page = payload;
    },
    [SET_TASK_COUNT]: (state, payload) => {
        state.taskCount = payload;
    },
    [RESET_FILTERS]: (state) => {
        state.page = 1;
        state.perPage = 9;
        state.startDate = moment().subtract(30, 'd');
        state.endDate = moment().add(1, 'd');
        state.search = '';
    },
};

const actions = {
    [GET_TASKS]: (context) => {
        context.commit(SET_LOADING, true);
        let filters = {
            start_date: moment(context.state.startDate).format(LARAVEL_DATE_FORMAT),
            end_date: moment(context.state.endDate).format(LARAVEL_DATE_FORMAT),
            search: context.state.search,
            page_number: context.state.page,
            per_page: context.state.perPage,
        };

        ApiService.get(`api/samples-by-user?` + querystring.stringify(filters)).then((response ) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_TASKS, response.data);
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [GET_TASK_COUNT]: (context) => {
        ApiService.get(`api/samples-by-user-count`).then((response) => {
            context.commit(SET_TASK_COUNT, response.data);
        }).catch((_) => {
           context.commit(SET_TASK_COUNT, 0);
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
